import type { SVGProps } from 'react';
export const NavLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 302 302">
    <g id="Group_630" data-name="Group 630" transform="translate(3028.604 1540.5) rotate(-180)">
      <g transform="matrix(-1, 0, 0, -1, 3028.6, 1540.5)" filter="url(#Rectangle_338)">
        <rect
          id="Rectangle_338-2"
          data-name="Rectangle 338"
          width="257"
          height="257"
          rx="128.5"
          transform="translate(278.5 278.5) rotate(-180)"
          fill="#fff"
        />
      </g>
      <path
        id="Union_1"
        data-name="Union 1"
        d="M3.012,95.159a10.281,10.281,0,0,1,0-14.541L34.544,49.085,3.012,17.553A10.284,10.284,0,0,1,17.557,3.012L55.9,41.352a10.258,10.258,0,0,1,3,7.734,10.258,10.258,0,0,1-3,7.734L17.557,95.159a10.286,10.286,0,0,1-14.545,0Z"
        transform="translate(2855.511 1339.412)"
        fill="#7b8191"
      />
    </g>
  </svg>
);
