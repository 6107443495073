import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '~components/generic/editor/Button/Button';
import Logo from '~components/generic/logo/Logo';
import { CheckIcon, CloseIcon } from '~components/icons';
import useLabels from '~hooks/useLabels';
import { IEntityComponentProps } from '~interfaces/props';
import { getEntityDefinition } from '~services/entity-definitions';
import { ControlActions } from '~store/actions';
import { getConfigAppName } from '~store/getter';
import { getNavigationType } from '~store/getter';

export interface ISidebarHeaderProps extends IEntityComponentProps {
  showSettings: boolean;
  onCloseSidebar: () => void;
  onSave: () => void;
  onDelete: () => void;
}

const SidebarHeader: FC<ISidebarHeaderProps> = ({ showSettings, onStopEdit, onCloseSidebar, data, onSave }) => {
  const entityDef = data && getEntityDefinition(data.type);
  const title = entityDef?.meta.name || 'Entity';
  const { msgSaveModuleHint, msgCancelModuleHint } = useLabels([
    'msgSaveModuleHint',
    'msgDeleteModuleHint',
    'msgCancelModuleHint',
    'msgMoveDownModuleHint',
    'msgMoveUpModuleHint',
    'msgEditModuleHint',
  ]);

  let navigationType = getNavigationType();

  const dispatch = useDispatch();

  const handleStopEdit = () => {
    onStopEdit();
    //set show settings to false
    dispatch(ControlActions.setShowSettings(false));
  };

  return (
    <div className="c-sidebar__header px-0">
      <div className="row">
        <div className="col  col-sm-3 navigation-button">
          {navigationType === 'top' && (
            <div className="flex">
              <Button title={msgCancelModuleHint} onClick={handleStopEdit} icon={<CloseIcon />} />
              <Button
                title={msgSaveModuleHint}
                onClick={() => {
                  onSave();
                  handleStopEdit();
                }}
                icon={<CheckIcon />}
              />
            </div>
          )}
          {navigationType === 'left' && showSettings ? (
            <a onClick={handleStopEdit} style={{ textDecoration: 'none' }}>
              <i className="c-icon-custom c-icon-custom--vertical-centered c-icon-custom--arrow-left u-size-lg" />
              <span className="u-sr-only">Stop edit</span>
            </a>
          ) : (
            <a onClick={onCloseSidebar} className="nav-sidebar-icon" style={{ textDecoration: 'none' }}>
              <i className="c-icon-custom c-icon-custom--vertical-centered c-icon-custom--burger u-size-lg" />
              <span className="u-sr-only">Collapse sidebar</span>
            </a>
          )}
        </div>
        <div className="col col-sm-9 u-margin-bottom-lg text-black o-text--right">
          {showSettings ? (
            <div className="">
              <h4>{title}</h4>
            </div>
          ) : (
            <a href="/" className="u-block">
              <div className="u-sr-only">Back to the main page</div>
              <div className="c-logo">
                <div className="c-logo__image">
                  <Logo />
                </div>
                <h2 className="c-logo__text">{getConfigAppName()}</h2>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;
