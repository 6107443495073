import { lookupSelector } from '~store/lookupSelector';
import { selectors as ConfigSelectors } from '~store/slices/config';
import { selectRootState } from './root';

// Root: Slice Selectors
export const selectConfigState = lookupSelector(selectRootState, state => state.config);

// Slice: Config Selectors
export const selectConfigApiPrefix = lookupSelector(selectConfigState, ConfigSelectors.apiPrefix);
export const selectConfigAppDocTitle = lookupSelector(selectConfigState, ConfigSelectors.appDocTitle);
export const selectConfigAppName = lookupSelector(selectConfigState, ConfigSelectors.appName);
export const selectConfigBackendUrl = lookupSelector(selectConfigState, ConfigSelectors.backendUrl);
export const selectConfigCssUrl = lookupSelector(selectConfigState, ConfigSelectors.cssUrl);
export const selectConfigCssPatcherUrl = lookupSelector(selectConfigState, ConfigSelectors.cssPatcherUrl);
export const selectConfigNavigationType = lookupSelector(selectConfigState, ConfigSelectors.navigationType);
export const selectConfigCustomJs = lookupSelector(selectConfigState, ConfigSelectors.customJs);
export const selectConfigDefaultLanguage = lookupSelector(selectConfigState, ConfigSelectors.defaultLanguage);
export const selectConfigDefaultPageUserGroups = lookupSelector(
  selectConfigState,
  ConfigSelectors.defaultPageUserGroups,
);
export const selectConfigFileBackendUrl = lookupSelector(selectConfigState, ConfigSelectors.fileBackendUrl);
export const selectConfigGoogleAnalyticsID = lookupSelector(selectConfigState, ConfigSelectors.googleAnalyticsID);
export const selectConfigIsAdminModeDisabled = lookupSelector(selectConfigState, ConfigSelectors.isAdminModeDisabled);
export const selectConfigIsPublicMode = lookupSelector(selectConfigState, ConfigSelectors.isPublicMode);
export const selectConfigLanguages = lookupSelector(selectConfigState, ConfigSelectors.languages);
export const selectConfigLogoUrl = lookupSelector(selectConfigState, ConfigSelectors.logoUrl);
export const selectConfigMatomoSiteId = lookupSelector(selectConfigState, ConfigSelectors.matomoSiteId);
export const selectConfigMatomoUrl = lookupSelector(selectConfigState, ConfigSelectors.matomoUrl);
export const selectConfigNotificationTimeout = lookupSelector(selectConfigState, ConfigSelectors.notificationTimeout);
export const selectConfigPagePrefix = lookupSelector(selectConfigState, ConfigSelectors.pagePrefix);
export const selectConfigSymfonyBackendUrl = lookupSelector(selectConfigState, ConfigSelectors.symfonyBackendUrl);
export const selectConfigMyFilesEnabled = lookupSelector(selectConfigState, ConfigSelectors.myFilesEnabled);
export const selectConfigMyFilesUrl = lookupSelector(selectConfigState, ConfigSelectors.myFilesUrl);
export const selectConfigSiteAccess = lookupSelector(selectConfigState, ConfigSelectors.siteAccess);
export const selectConfigTrackingClients = lookupSelector(selectConfigState, ConfigSelectors.trackingClients);
export const selectConfigFallbackForbiddenUrl = lookupSelector(selectConfigState, ConfigSelectors.fallbackForbiddenUrl);
export const selectConfigDefaultMediaLibraryRealm = lookupSelector(
  selectConfigState,
  ConfigSelectors.defaultMediaLibraryRealm,
);
export const selectConfigLogoutUrl = lookupSelector(selectConfigState, ConfigSelectors.logoutUrl);

export const selectConfigIsMediaLibraryMaintenance = lookupSelector(
  selectConfigState,
  ConfigSelectors.isMediaLibraryMaintenance,
);
