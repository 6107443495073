import type { SVGProps } from 'react';
export const ArrowUpRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 1024 1024"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M732.3,806.3l78.9-557.8c2.7-19.4-10.8-37.4-30.2-40.2-1.7-.2-3.4-.3-5.1-.3l-563.2.6c-4.9,0-8.9,4-8.9,8.9s.7,4.2,2.2,5.8l59.1,68c3.4,3.9,8.2,6.1,13.5,6.1l371.6-.3-472.7,410.9c-3.7,3.2-4.1,8.9-.8,12.6l43.8,50.4c3.2,3.7,8.9,4.1,12.6.8l472.7-410.9-52.1,367.9c-.7,5.1.7,10.2,4.2,14.1l59.1,68c5,5.7,14.3,3,15.5-4.6h-.1Z"></path>
  </svg>
);
