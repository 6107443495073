import { connect } from 'react-redux';

import type { RootState } from '$store';
import NavbarHeaderUser, {
  TopNavBarUserProps,
} from '~components/navigation/topnavbar/NavbarHeaderUser/NavbarHeaderUser';
import { useAppLogin } from '~services/env';
import { SessionActions } from '~store/actions';
import {
  selectConfigIsAdminModeDisabled,
  selectConfigLanguages,
  selectContextCurrentAppLanguage,
  selectSessionIsEditing,
  selectUserId,
} from '~store/selectors';
import { selectContext } from '~store/selectors/control';

export type StateProps = Pick<
  TopNavBarUserProps,
  'context' | 'isEditing' | 'currentLanguage' | 'appLanguages' | 'userId' | 'useAppLogin' | 'isEditmodeDisabled'
>;

const mapStateToProps = (state: RootState): StateProps => {
  const userId = selectUserId(state) || 0;
  return {
    context: selectContext(state),
    isEditing: selectSessionIsEditing(state),
    currentLanguage: selectContextCurrentAppLanguage(state),
    appLanguages: selectConfigLanguages(state),
    isEditmodeDisabled: selectConfigIsAdminModeDisabled(state),
    userId,
    useAppLogin,
  };
};

export type DispatchProps = Pick<TopNavBarUserProps, 'onDisableEditing' | 'onEnableEditing'>;
const mapDispatchToProps: DispatchProps = {
  onEnableEditing: SessionActions.enableEditing,
  onDisableEditing: SessionActions.disableEditing,
};

export const NavbarHeaderUserContainer = connect(mapStateToProps, mapDispatchToProps)(NavbarHeaderUser);
export default NavbarHeaderUserContainer;
