import clsx from 'clsx';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { ModuleTypes } from '$const';
import { Link } from '~components/generic/elements/Link/Link';
import { ChevronDownIcon } from '~components/icons/ChevronDownIcon';
import { ChevronRightIcon } from '~components/icons/ChevronRightIcon';
import Dropdown from '../Dropdown/Dropdown';

interface MenuItem {
  link?: string;
  title?: string;
  hasDraft?: boolean;
  isPublished?: boolean;
  path?: string;
  alias?: string;
  id?: string;
  children?: any[];
  parent?: string | null;
  type?: 'page' | 'link';
  fetchAlias?: string;
  permissions?: {
    view?: boolean;
    update?: boolean;
    create?: boolean;
    delete?: boolean;
    publish?: boolean;
    set_grants?: boolean;
    view_draft?: boolean;
  };
  expanded?: boolean;
}

interface MenuItemsProps {
  item: MenuItem;
  depthLevel: number;
  activePath?: string;
  isEditMode?: boolean;
}

const MenuItems = ({ item, depthLevel, activePath, isEditMode }: MenuItemsProps) => {
  const [dropdown, setDropdown] = useState(false);
  const [portalPosition, setPortalPosition] = useState({
    top: 0,
    left: 0,
    rightOfViewport: 0,
    height: 0,
    width: 0,
    submenuOutOfViewPort: false,
  });
  const [submenuOutOfViewport, setSubmenuOutOfViewport] = useState(false);

  let ref = useRef(null);

  const clsItem = clsx('menu-item', {
    active: activePath === item.alias,
    unpublished: isEditMode && !item.isPublished,
    'has-draft': isEditMode && item.isPublished && item.hasDraft,
  });

  const calculatePortalPosition = () => {
    if (ref.current) {
      // @ts-ignore
      const rect = ref.current.getBoundingClientRect();

      function isValueNearThreshold(value1, value2, threshold) {
        return Math.abs(value1 - value2) <= threshold;
      }

      const { top, left, right, height, width } = rect;
      const rightOfViewport = window.innerWidth - left;
      let submenuOutOfViewPort;

      if (isValueNearThreshold(right, window.innerWidth, 100)) {
        setSubmenuOutOfViewport(true);
      } else {
        // setSubmenuOutOfViewport(false);
      }

      setPortalPosition({ top, left, rightOfViewport, height, width, submenuOutOfViewPort });
    }
  };

  useEffect(() => {
    calculatePortalPosition();
  }, [dropdown]);

  const onMouseEnter = () => {
    if (item.children.length > 0) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const onClick = () => {
    setDropdown(false);
  };

  const dropdownContainerStyle = (): CSSProperties => {
    if (submenuOutOfViewport) {
      if (depthLevel > 0) {
        return {
          position: 'absolute',
          top: portalPosition.top - 4,
          left: portalPosition.rightOfViewport,
        };
      } else {
        return {
          position: 'absolute',
          top: depthLevel > 0 ? portalPosition.top - portalPosition.height + 4 : portalPosition.top - 4,
          left: portalPosition.left,
        };
      }
    } else {
      return {
        position: 'absolute',
        top: depthLevel > 0 ? portalPosition.top - portalPosition.height + 4 : portalPosition.top - 4,
        left: depthLevel > 0 ? portalPosition.left + 200 : portalPosition.left,
      };
    }

    // {
    //   position: 'absolute',
    //       top: depthLevel > 0 ? portalPosition.top - portalPosition.height + 4 : portalPosition.top - 4,
    //     // left: depthLevel > 0 ? portalPosition.left + 200 : portalPosition.left,
    //     right: portalPosition.rightOfViewport,
    // }
  };

  return (
    <li className={clsItem} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
      {item.path && item.children.length > 0 ? (
        <>
          {item.type === 'page' && (
            <Link id={`${item.alias}#${item.id}~${ModuleTypes.internalLink}`}>
              {item.title}
              {depthLevel > 0 ? (
                <ChevronRightIcon className="w-3 h-3 ml-2" />
              ) : (
                <ChevronDownIcon className="w-3 h-3 ml-2" />
              )}
            </Link>
          )}
          {item.type === 'link' && (
            <a href="#" onClick={() => {}}>
              {item.title}
            </a>
          )}
          {ReactDOM.createPortal(
            <div className={`c-app__topbar level-${depthLevel}`} style={dropdownContainerStyle()}>
              <Dropdown depthLevel={depthLevel} submenus={item.children} dropdown={dropdown} />{' '}
            </div>,
            document.body,
          )}
        </>
      ) : !item.path && item.children.length > 0 ? (
        <>
          {item.type === 'page' && (
            <Link id={`${item.alias}#${item.id}~${ModuleTypes.internalLink}`}>{item.title}</Link>
          )}
          {item.type === 'link' && (
            <a href="#" onClick={() => {}}>
              {item.title}
              {depthLevel > 0 ? (
                <ChevronRightIcon className="w-3 h-3 ml-2" />
              ) : (
                <ChevronDownIcon className="w-3 h-3 ml-2" />
              )}
            </a>
          )}

          {ReactDOM.createPortal(
            <div className="c-app__topbar" style={dropdownContainerStyle()}>
              <Dropdown depthLevel={depthLevel} submenus={item.children} dropdown={dropdown} />{' '}
            </div>,
            document.body,
          )}
        </>
      ) : (
        <Link id={`${item.alias}#${item.id}~${ModuleTypes.internalLink}`}>{item.title}</Link>
      )}
    </li>
  );
};

export default MenuItems;
