import clsx from 'clsx';
import { MouseEvent, PureComponent } from 'react';

import Loader from '~components/generic/loader/Loader';
import { Navigation } from './Navigation';

export interface ISidebarMenuProps {
  menuElements: MenuElement[];
  activePath: string;
  onToggleMenu: (elementId: string) => void;
  sidebarWidgets: WidgetSidebar[];
  isEditMode: boolean;
}

export class SidebarMenu extends PureComponent<ISidebarMenuProps> {
  onClickRoot = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.parentNode.querySelector('.c-icon-custom').classList.toggle('c-icon-custom--caret-down');
    (event.currentTarget.nextSibling as HTMLElement).classList.toggle('u-sr-only');
    (event.currentTarget.parentNode as HTMLElement).classList.toggle('c-nav__item--active');
  };

  handleElementToggle = (elementId: string) => this.props.onToggleMenu(elementId);

  render() {
    const { menuElements, isEditMode, activePath, sidebarWidgets } = this.props;

    return (
      <div className="c-sidebar__outside-sidebar">
        <div className="c-sidebar__menu">
          <div className="d-flex">
            <div className="col col-sm-12 px-0 relative u-overflow-hidden">
              <span className="navHeadline">BRAND</span>
              {menuElements.length === 0 ? (
                <Loader theme="dark" />
              ) : (
                <div className="c-nav js-nav c-nav--dark c-nav--unpad c-nav--hide-inactive-trail">
                  {
                    <div className="c-nav__item c-nav__item--page c-nav__item--first-level c-nav__item--active">
                      <a className="c-nav__item--first-level-sp" onClick={this.onClickRoot}>
                        <strong>Brand Guide</strong>
                        <i className="c-icon-custom c-icon-custom--caret-up u-absolute u-absolute--right" />
                      </a>
                      <div
                        className={clsx(
                          'c-nav__item-subnav',
                          isEditMode && 'app-edit-mode',
                          !isEditMode && 'app-no-edit-mode',
                        )}
                      >
                        <Navigation
                          elements={menuElements}
                          activeAlias={activePath}
                          onElementToggle={this.handleElementToggle}
                        />
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="o-grid">
          {sidebarWidgets.map((item, i) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(item.data, 'text/html');
            const content = doc.body;

            return (
              <div
                key={i}
                className="sidebar-widget"
                dangerouslySetInnerHTML={{ __html: content ? content.innerHTML : '' }}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default SidebarMenu;
