import { createRef, FormEvent } from 'react';

import Button from '~components/generic/elements/Button';
import useAppDispatch from '~hooks/useAppDispatch';
import { goToSearchPage } from '~store/thunks/search';
import styles from './NavbarHeaderSearch.css';

export function NavbarHeaderSearch() {
  const dispatch = useAppDispatch();
  const refInput = createRef<HTMLInputElement>();

  function handleSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    if (!refInput.current) return;

    const value = refInput.current.value || '';
    if (!value) {
      refInput.current.focus();
    }
    if (!!value) {
      dispatch(goToSearchPage(value));
      refInput.current.blur();
      refInput.current.value = '';
    }
  }

  return (
    <div className="c-sidebar__search ">
      <div className="c-region c-region--search">
        <form id="search-form" onSubmit={handleSubmit}>
          <div className="form--inline form-inline clearfix">
            <div className={styles.inputContainer}>
              <input
                placeholder="Search"
                type="text"
                ref={refInput}
                className="form-text c-input u-full-width"
                aria-label="Search term"
              />

              <Button
                type="submit"
                label="Search"
                hideLabel={true}
                iconName="search"
                iconSize="lg"
                className={styles.searchIcon}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
