import React from 'react';

import Logo from '~components/generic/logo/Logo';
import { NavbarHeaderSearch } from '~components/navigation/topnavbar/NavbarHeaderSearch/NavbarHeaderSearch';
import NavbarHeaderUserContainer from '~components/navigation/topnavbar/NavbarHeaderUser/NavbarHeaderUserContainer';
import { getConfigAppName } from '~store/getter';
import styles from './NavbarHeader.css';

const NavbarHeader = () => {
  return (
    <div className={styles.container}>
      <div className="flex justify-between align-items-center ">
        <div className="flex align-items-center">
          <a href="/" className="u-block">
            <div className="c-logo">
              <div className="c-logo__image">
                <Logo />
              </div>
              <h2 className="c-logo__text">{getConfigAppName()}</h2>
            </div>
          </a>
        </div>
        <NavbarHeaderSearch />
        <div>
          <NavbarHeaderUserContainer />
        </div>
      </div>
    </div>
  );
};

export default NavbarHeader;
