import MenuItems from '../MenuItems/MenuItems';

interface Submenu {
  title: string;
  link: string;
}

interface DropdownProps {
  submenus: Submenu[];
  dropdown: boolean;
  depthLevel: number;
}

const Dropdown = ({ submenus, dropdown, depthLevel }: DropdownProps) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';

  if (dropdown) {
    return (
      <ul data-dropdown className={`dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}>
        {submenus.map((submenu, index) => (
          <MenuItems item={submenu} key={index} depthLevel={depthLevel} />
        ))}
      </ul>
    );
  }
};

export default Dropdown;
