import type { SVGProps } from 'react';
export const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
  </svg>
);
