import getStore from '$store';
import {
  selectConfigApiPrefix,
  selectConfigAppName,
  selectConfigBackendUrl,
  selectConfigCssPatcherUrl,
  selectConfigCssUrl,
  selectConfigFileBackendUrl,
  selectConfigIsPublicMode,
  selectConfigLogoUrl,
  selectConfigLogoutUrl,
  selectConfigNavigationType,
  selectConfigNotificationTimeout,
  selectConfigSiteAccess,
  selectConfigState,
  selectConfigSymfonyBackendUrl,
} from './selectors';

type UnknownFunction = (...args: any[]) => any;
const wrapState = <T extends UnknownFunction, R extends ReturnType<T>>(fn: T): (() => R) => {
  return function () {
    const state = getStore().getState();
    return fn(state);
  };
};

// Root: Slice Getter
export const getConfigState = wrapState(selectConfigState);

// Slice: Config Getter
export const getConfigApiPrefix = wrapState(selectConfigApiPrefix);
// export const getConfigAppDocTitle = wrapState(selectConfigAppDocTitle);
export const getConfigAppName = wrapState(selectConfigAppName);
export const getConfigBackendUrl = wrapState(selectConfigBackendUrl);
export const getConfigCssUrl = wrapState(selectConfigCssUrl);
export const getNavigationType = wrapState(selectConfigNavigationType);
export const getConfigCssPatcherUrl = wrapState(selectConfigCssPatcherUrl);
// export const getConfigDefaultLanguage = wrapState(selectConfigDefaultLanguage);
// export const getConfigDefaultPageUserGroups = wrapState(selectConfigDefaultPageUserGroups);
export const getConfigFileBackendUrl = wrapState(selectConfigFileBackendUrl);
// export const getConfigGoogleAnalyticsID = wrapState(selectConfigGoogleAnalyticsID);
// export const getConfigIsAdminModeDisabled = wrapState(selectConfigIsAdminModeDisabled);
export const getConfigIsPublicMode = wrapState(selectConfigIsPublicMode);
// export const getConfigLanguages = wrapState(selectConfigLanguages);
export const getConfigLogoUrl = wrapState(selectConfigLogoUrl);
// export const getConfigMatomoSiteId = wrapState(selectConfigMatomoSiteId);
// export const getConfigMatomoUrl = wrapState(selectConfigMatomoUrl);
export const getConfigNotificationTimeout = wrapState(selectConfigNotificationTimeout);
// export const getConfigPagePrefix = wrapState(selectConfigPagePrefix);
export const getConfigSymfonyBackendUrl = wrapState(selectConfigSymfonyBackendUrl);
// export const getConfigMyFilesEnabled = wrapState(selectConfigMyFilesEnabled);
// export const getConfigMyFilesUrl = wrapState(selectConfigMyFilesUrl);
export const getConfigSiteAccess = wrapState(selectConfigSiteAccess);
export const getConfigLogoutUrl = wrapState(selectConfigLogoutUrl);

// Computed
export const getAbsoluteUrl = (relativePath: string) => {
  const fileBackendUrl = getConfigFileBackendUrl();
  return `${fileBackendUrl ? fileBackendUrl : ''}${relativePath}`;
};
