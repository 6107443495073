import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';

import { NavLeftArrow } from '~components/icons/NavLeftArrow';
import { NavRightArrow } from '~components/icons/NavRightArrow';
import MenuItems from '~components/navigation/Navbar/MenuItems/MenuItems';
import { useResponsiveDimensions } from '~hooks/useResponsiveDimensions';
import { ControlActions } from '~store/actions';
import { NavigationStyle } from '~store/enums/navigation';

export interface INavbarMenuProps {
  menuElements: MenuElement[];
  activePath: string;
  onToggleMenu: (elementId: string) => void;
  sidebarWidgets: WidgetSidebar[];
  isEditMode: boolean;
}

const Navbar = ({ menuElements, sidebarWidgets, isEditMode, activePath }: INavbarMenuProps) => {
  const sliderRef = useRef();
  const [showRightArrow, setShowRightArrow] = useState<boolean>();
  const [toolsDropdown, setToolsDropdown] = useState(false);
  const isMobile = useResponsiveDimensions().isMobile;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      dispatch(ControlActions.setNavigationStyle(NavigationStyle.VERTICAL));
    }
  }, [dispatch, isMobile]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      handleArrowRight();
    });

    const slickList = document.querySelector('.slick-list');
    if (slickList) {
      observer.observe(slickList, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  const handleArrowRight = () => {
    const slickList = document.querySelector('.slick-list'); // Select the .slick-list element
    const slides = slickList.querySelectorAll('.slick-slide');

    setTimeout(() => {
      slides.forEach(slide => {
        const slideRect = slide.getBoundingClientRect();
        if (
          slideRect.left > window.innerWidth // Slide is to the right of the viewport
        ) {
          setShowRightArrow(true);
        } else {
          setShowRightArrow(false);
        }
      });
    }, 1000);
  };

  const settings = {
    swipe: false,
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: showRightArrow ? <NavRightArrow /> : <div />,
    prevArrow: <NavLeftArrow />,
    // onReInit: handleArrowRight,
  };

  return (
    <div className={clsx('c-app__topbar', 'topbar-container')}>
      <Slider {...settings} ref={sliderRef} beforeChange={handleArrowRight}>
        <div className="tools-container">
          {sidebarWidgets.map((item, i) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(item.data, 'text/html');
            const reactPortalDiv = doc.querySelector('.top-navigation-bar-portal');
            const content = doc.querySelector('.top-navigation-bar-item');

            return (
              <div key={i} onMouseEnter={() => setToolsDropdown(true)} onMouseLeave={() => setToolsDropdown(false)}>
                {content && content.innerHTML && (
                  <div className="top-navigation-bar" dangerouslySetInnerHTML={{ __html: content.innerHTML }}></div>
                )}
                {toolsDropdown &&
                  reactPortalDiv &&
                  reactPortalDiv.innerHTML &&
                  ReactDOM.createPortal(
                    <div dangerouslySetInnerHTML={{ __html: reactPortalDiv.innerHTML }}></div>,
                    document.body,
                  )}
              </div>
            );
          })}
        </div>

        {menuElements.length > 0 &&
          menuElements.map((item, index) => {
            const depthLevel = 0;
            return (
              <MenuItems
                isEditMode={isEditMode}
                item={item}
                key={index}
                depthLevel={depthLevel}
                activePath={activePath}
              />
            );
          })}

        {/* Tools Navigation here */}
        <div className="o-grid tools-navigation-top">
          {sidebarWidgets.map((item, i) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(item.data, 'text/html');
            const content = doc.body;

            // Find the div with the class "tools-navigation-top"
            const toolsNavigationTopDiv = content.querySelector('.tools-navigation-top');

            return (
              <div
                key={i}
                className="sidebar-widget"
                dangerouslySetInnerHTML={{ __html: toolsNavigationTopDiv ? toolsNavigationTopDiv.outerHTML : '' }}
              />
            );
          })}
        </div>
      </Slider>
    </div>
  );
};

export default Navbar;
