import clsx from 'clsx';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { URL_REPLACEMENT } from '$const';
import { Button } from '~components/generic/editor/Button/Button';
import RouterLink from '~components/generic/RouterLink';
import { SettingsIcon } from '~components/icons';
import { SidebarUserToggleEditing } from '~components/sidebar/user/SidebarUserToggleEditing';
import usePermissions from '~hooks/usePermissions';
import { registerSLOHandlers } from '~services/api/calls/user';
import { getConfigLogoutUrl } from '~store/getter';
import replaceUrl from '~utils/replaceUrl';
import { ButtonLanguage } from './ButtonLanguage';
import { SettingsMenu } from './SettingsMenu';

export interface SidebarUserProps {
  isEditing: boolean;
  onEnableEditing: () => void;
  onDisableEditing: () => void;
  context: string | void;
  userId: number;
  currentLanguage: string;
  appLanguages: Language[];
  useAppLogin: boolean;
  isEditmodeDisabled: boolean;
}

export const SidebarUser = ({
  isEditing,
  onEnableEditing,
  onDisableEditing,
  context,
  isEditmodeDisabled,
  userId,
  currentLanguage,
  appLanguages,
  useAppLogin,
}: SidebarUserProps) => {
  const [settingsMenu, setSettingsMenu] = useState<{ x: number; y: number }>(undefined);
  const { hasToggleEditmodePermission } = usePermissions();
  const logoutUrl = getConfigLogoutUrl();

  const btnSettingsClickHandler = useCallback((e: MouseEvent) => {
    const rect = (e.target as HTMLButtonElement).getBoundingClientRect();
    setSettingsMenu({ x: rect.left, y: rect.top });
  }, []);
  const handleSettingsMenuOnClose = useCallback(() => setSettingsMenu(undefined), []);

  const clsContainer = clsx('c-sidebar__user row align-items-center');
  const clsColumnLogout = clsx('col col-sm-4');
  const clsColumnEditToggle = clsx('col col-sm-2');
  const clsColumnLangSwitch = clsx('col col-sm-4 language-link');
  const clsColumnSettings = clsx('col col-sm-2');
  const clsColumnSpacer = clsx('hidden');
  const clsButtonSettings = clsx('settings-button');

  // when the element is ready rendered, run initialize method of SLO again
  useEffect(registerSLOHandlers, []);

  return (
    <div className={clsContainer}>
      <div className={clsColumnLogout}>
        {!userId && !useAppLogin && (
          <a href={replaceUrl(`${URL_REPLACEMENT.BACKEND_URL}/user/login`)} className="c-button c-button--dark">
            <FormattedMessage id="btnLabelLogin" defaultMessage="Login" />
          </a>
        )}
        {!userId && useAppLogin && (
          <RouterLink className="c-button c-button--dark" to="/user/login">
            <FormattedMessage id="btnLabelLogin" defaultMessage="Login" />
          </RouterLink>
        )}
        {!context && useAppLogin && (
          <RouterLink disabled={context} className="c-button c-button--dark" to="/user/logout">
            <FormattedMessage id="btnLabelLogout" defaultMessage="Logout" />
          </RouterLink>
        )}
        {!context && !useAppLogin && !!userId && (
          <a href={replaceUrl(`${URL_REPLACEMENT.BACKEND_URL}${logoutUrl}`)} className="c-button c-button--dark">
            <FormattedMessage id="btnLabelLogout" defaultMessage="Logout" />
          </a>
        )}
        {!!context && (
          <button className="c-button c-button--dark" disabled>
            <FormattedMessage id="btnLabelLogout" defaultMessage="Logout" />
          </button>
        )}
      </div>
      <div className={clsColumnSpacer} />
      <div className={clsColumnEditToggle}>
        {!isEditmodeDisabled && hasToggleEditmodePermission && (
          <SidebarUserToggleEditing
            disabled={!!context}
            isEditing={isEditing}
            onEnableEditing={onEnableEditing}
            onDisableEditing={onDisableEditing}
          />
        )}
      </div>
      <div className={clsColumnLangSwitch}>
        <ButtonLanguage languages={appLanguages} currentLanguage={currentLanguage} />
      </div>
      <div className={clsColumnSpacer} />
      <div className={clsColumnSettings}>
        <Button unflashy icon={<SettingsIcon />} className={clsButtonSettings} onClick={btnSettingsClickHandler} />
      </div>
      {settingsMenu && (
        <SettingsMenu
          position="BOTTOM_LEFT"
          userId={userId}
          pageX={settingsMenu.x}
          pageY={settingsMenu.y}
          onClose={handleSettingsMenuOnClose}
        />
      )}
    </div>
  );
};

export default SidebarUser;
