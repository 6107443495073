import { connect } from 'react-redux';

import type { RootState } from '$store';
import Navbar, { INavbarMenuProps } from '~components/navigation/Navbar/Navbar';
import { MenuActions } from '~store/actions';
import {
  selectLocationPath,
  selectMenuElementsForSidebar,
  selectSessionIsEditing,
  selectWidgetsSidebar,
} from '~store/selectors';

export type StateProps = Pick<INavbarMenuProps, 'menuElements' | 'activePath' | 'isEditMode' | 'sidebarWidgets'>;
const mapStateToProps = (state: RootState): StateProps => ({
  isEditMode: selectSessionIsEditing(state),
  menuElements: selectMenuElementsForSidebar(state),
  activePath: selectLocationPath(state),
  sidebarWidgets: selectWidgetsSidebar(state),
});
export type DispatchProps = Pick<INavbarMenuProps, 'onToggleMenu'>;
const mapDispatchToProps: DispatchProps = {
  onToggleMenu: MenuActions.toggleSubmenuById,
};

export const NavbarContainer = connect(mapStateToProps, mapDispatchToProps)(Navbar);
export default NavbarContainer;
