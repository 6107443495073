import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { NavigationStyle } from '~store/enums/navigation';

// State
export type ControlState = {
  activeAnchor?: string;
  activeMenu?: string;
  activeMenuItem?: string;
  anchorLinkAutosuggestSearchResult?: {
    anchors?: Entity[];
    links?: InternalLink[];
  };
  context: string;
  downloadFile?: Record<string, DownloadFile>;
  emptyPageTemporaryEntityId?: string;
  navigationStyle: string;
  sidebarIsCollapsed: boolean;
  targetModule?: string;
  temporaryEntityReferences: {
    [tempId: string]: {
      fieldName: string;
      parentId: string;
    }[];
  };
  playVideo?: PlayVideo;
  workableEntities: Record<string, Entity>;
  lockedPageId: string;
  showSettings: boolean;
};

// Initial State
export const initialState: ControlState = {
  navigationStyle: NavigationStyle.VERTICAL,
  activeAnchor: null,
  activeMenu: null,
  activeMenuItem: null,
  anchorLinkAutosuggestSearchResult: {
    anchors: [],
    links: [],
  },
  context: null,
  emptyPageTemporaryEntityId: null,
  sidebarIsCollapsed: false,
  targetModule: null,
  temporaryEntityReferences: {},
  workableEntities: {},
  downloadFile: {},
  lockedPageId: null,
  showSettings: false,
};

// Reducers
const setActiveAnchor: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.activeAnchor = action.payload || initialState.activeAnchor;
};
const setTargetModule: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.targetModule = action.payload || initialState.targetModule;
};
const setSidebarState: CaseReducer<ControlState, PayloadAction<boolean>> = (state, action) => {
  state.sidebarIsCollapsed = action.payload || initialState.sidebarIsCollapsed;
};
const setNavigationStyle: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  console.log('on change of navigation style', action.payload);
  state.navigationStyle = action.payload;
};
const openSidebar: CaseReducer<ControlState> = state => {
  state.sidebarIsCollapsed = false;
};
const closeSidebar: CaseReducer<ControlState> = state => {
  state.sidebarIsCollapsed = true;
};
const setEmptyPageTemporaryEntityId: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.emptyPageTemporaryEntityId = action.payload || initialState.emptyPageTemporaryEntityId;
};
const setTemporaryEntityReference: CaseReducer<ControlState, PayloadAction<TemporaryEntityReference>> = (
  state,
  action,
) => {
  state.temporaryEntityReferences[action.payload.tempId] = [
    ...(state.temporaryEntityReferences[action.payload.tempId] || []),
    {
      parentId: action.payload.parentId,
      fieldName: action.payload.fieldName,
    },
  ];
};
const unsetTemporaryEntityReference: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  delete state.temporaryEntityReferences[action.payload];
};
const setActiveMenu: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.activeMenu = action.payload || initialState.activeMenu;
};
const setActiveMenuItem: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.activeMenuItem = action.payload || initialState.activeMenuItem;
};
const setContext: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.context = action.payload || initialState.context;
};
// const setParentContext: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
//   state.parentContext = action.payload;
// };
const setShowSettings: CaseReducer<ControlState, PayloadAction<boolean>> = (state, action) => {
  state.showSettings = action.payload;
};

const setWorkableEntity: CaseReducer<ControlState, PayloadAction<Entity>> = (state, action) => {
  if (action.payload) {
    state.workableEntities[action.payload.id] = action.payload;
  }
};
const unsetWorkableEntity: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  delete state.workableEntities[action.payload];
};
const unsetWorkableEntities: CaseReducer<ControlState, PayloadAction<string[]>> = (state, action) => {
  for (const entityId of action.payload) {
    delete state.workableEntities[entityId];
  }
};
const setWorkableEntityChange: CaseReducer<ControlState, PayloadAction<Partial<Entity>>> = (state, action) => {
  if (action.payload) {
    state.workableEntities[action.payload.id] = {
      ...state.workableEntities[action.payload.id],
      ...action.payload,
      _changed: true,
    };
  }
};
const triggerAnchorLinkAutosuggestSearchSuccess: CaseReducer<
  ControlState,
  PayloadAction<typeof initialState.anchorLinkAutosuggestSearchResult>
> = (state, action) => {
  state.anchorLinkAutosuggestSearchResult = action.payload || initialState.anchorLinkAutosuggestSearchResult;
};
const downloadFile: CaseReducer<ControlState, PayloadAction<DownloadFile>> = (state, action) => {
  if (action.payload) {
    state.downloadFile[action.payload.filename] = action.payload;
  }
};
const playVideo: CaseReducer<ControlState, PayloadAction<PlayVideo>> = (state, action) => {
  state.playVideo = action.payload || initialState.playVideo;
};

const lockPage: CaseReducer<ControlState, PayloadAction<string>> = (state, action) => {
  state.lockedPageId = action.payload || null;
};

function isLockPageAction(action: AnyAction) {
  return action?.type?.startsWith('page/lock') || false;
}

// Slice
const slice = createSlice({
  name: 'control',
  initialState,
  reducers: {
    downloadFile,
    playVideo,
    setActiveAnchor,
    setActiveMenu,
    setActiveMenuItem,
    setContext,
    setEmptyPageTemporaryEntityId,
    setSidebarState,
    setNavigationStyle,
    setTargetModule,
    setTemporaryEntityReference,
    setWorkableEntity,
    setWorkableEntityChange,
    triggerAnchorLinkAutosuggestSearchSuccess,
    unsetTemporaryEntityReference,
    unsetWorkableEntity,
    unsetWorkableEntities,
    openSidebar,
    closeSidebar,
    lockPage,
    setShowSettings,
  },
  extraReducers: builder => {
    builder.addMatcher(isLockPageAction, (state, action) => {
      if (action.type.endsWith('fulfilled')) {
        state.lockedPageId = action.meta.arg;
        return;
      }
      state.lockedPageId = null;
    });
  },
});

// Export: Actions
export const actions = slice.actions;

// Export: Reducer
export default slice.reducer;

// Selectors
const selectSelf = (state: ControlState) => state;
export const selectors = {
  self: selectSelf,
  activeAnchor: (state: ControlState) => state.activeAnchor,
  activeMenu: (state: ControlState) => state.activeMenu,
  activeMenuItem: (state: ControlState) => state.activeMenuItem,
  anchorLinkAutosuggestSearchResult: (state: ControlState) => state.anchorLinkAutosuggestSearchResult,
  showSettings: (state: ControlState) => state.showSettings,
  context: (state: ControlState) => state.context,
  downloadFile: (state: ControlState) => state.downloadFile,
  emptyPageTemporaryEntityId: (state: ControlState) => state.emptyPageTemporaryEntityId,
  navigationStyle: (state: ControlState) => state.navigationStyle,
  sidebarIsCollapsed: (state: ControlState) => state.sidebarIsCollapsed,
  targetModule: (state: ControlState) => state.targetModule,
  temporaryEntityReferences: (state: ControlState) => state.temporaryEntityReferences,
  playVideo: (state: ControlState) => state.playVideo,
  workableEntities: (state: ControlState) => state.workableEntities,
  lockedPageId: (state: ControlState) => state.lockedPageId,
};
