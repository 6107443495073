import { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import type { AppDispatch } from '$store';
import type { RootState } from '$store';
import { IEntityContainerProps } from '~interfaces/props';
import { getEntityDefinition } from '~services/entity-definitions';
import { IEntityDefinition } from '~services/entity-definitions/interface';
import log from '~services/log';
import { ControlActions } from '~store/actions';
import {
  makeGetDeeplyReferencedEntityWithChanges,
  selectAddonsIsPublishingEnabled,
  selectDraftEntityById,
  selectDraftEntityStatusById,
  selectEntityType,
  selectEntityWithChangesById,
  selectSessionIsEditing,
  selectSessionOptions,
  selectShowSettings,
  selectUserCanEditPage,
} from '~store/selectors';
import { selectContext, selectWorkableEntities } from '~store/selectors/control';
import { addChild, cancelChild, changeChild, deleteChild, saveChild } from '~store/thunks/child';
import { changeWorkableEntity, saveEntityWorker, startEditingEntity, stopEditingEntity } from '~store/thunks/entity';
import { uploadFiles } from '~store/thunks/file';
import { moveModule } from '~store/thunks/module';

function makeEntitySelector(state: RootState, id: string) {
  return id && selectEntityWithChangesById(state, id);
}

export type StateProps = {
  canEdit: boolean;
  context: string;
  data: Entity;
  editing: boolean;
  entityDef: IEntityDefinition; // ToDo: Refactor to 'EntityDefinition'
  extraData: EntityExtraData;
  isEditing: boolean;
  isPublishingEnabled: boolean;
  options: Partial<Session>;
  origData: string;
  origExtraData: EntityExtraData;
  origExtraDataFieldNames: Record<string, string[]>;
  stable: boolean;
  status: EntityInformationStatus;
  type: string;
  workable: boolean;
};

function mapStateToProps(
  state: RootState,
  { entityId, extraDataFieldNames, extraData = {}, editing, parentIsStable, extraSelectors }: IEntityContainerProps,
): StateProps {
  try {
    const entity = makeEntitySelector(state, entityId);
    const isPublishingEnabled = selectAddonsIsPublishingEnabled(state);
    const isEditing = selectSessionIsEditing(state);

    let _canEdit = false;
    // check if app is in edit mode and current user has edit permissions
    if (isEditing && selectUserCanEditPage(state)) {
      // user only can edit entity if publishing is disabled or a draft is available
      if (!isPublishingEnabled || selectDraftEntityById(state, entityId) !== null) {
        _canEdit = true;
      }
    }

    const workableEntities = selectWorkableEntities(state);
    const workableEntity = workableEntities[entityId] || undefined;
    const isEntityWorkable = !!workableEntity;
    const entityType = selectEntityType(state, entityId);
    const entityStatus = isPublishingEnabled
      ? selectDraftEntityStatusById(state, entityId)
      : selectDraftEntityStatusById(state, entityId);
    const context = selectContext(state);
    const showSettings = selectShowSettings(state);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: StateProps = {
      context,
      options: selectSessionOptions(state),
      isEditing,
      origData: entityId,
      workable: isEntityWorkable,
      editing: editing || (entityId === context && !showSettings) || false,
      canEdit: _canEdit,
      extraData: {},
      origExtraData: extraData,
      origExtraDataFieldNames: extraDataFieldNames,
      data: entity,
      // ToDo: use entityInfo for this because it is earlier
      type: entityType,
      status: entityStatus,
      stable:
        parentIsStable !== false &&
        entityStatus &&
        (entityStatus.isComplete == null || entityStatus.isComplete === true),
      entityDef: entityType && getEntityDefinition(entityType),
      isPublishingEnabled,
    };

    // inject selectors
    if (extraSelectors) {
      Object.keys(extraSelectors).map(dataField => (res[dataField] = extraSelectors[dataField](state)));
    }
    // get extra data from fieldNames for deeply nested entity references
    if (extraDataFieldNames) {
      Object.keys(extraDataFieldNames).forEach(key => {
        res.extraData[key] = makeGetDeeplyReferencedEntityWithChanges(entityId, extraDataFieldNames[key])(state);
      });
    }

    // inject the store subscriptions to the extra data entities into the extra
    // data prop in the form of { dataKey: entity }
    if (extraData) {
      Object.keys(extraData)
        .filter(key => extraData[key])
        .forEach(key => {
          const value = extraData[key];
          // handle arrays and single values. (probably over-engineered :p)
          res.extraData[key] =
            value.constructor === Array
              ? value.map(id => makeEntitySelector(state, id))
              : makeEntitySelector(state, value as string);
        });
    }

    return res;
  } catch (e) {
    log(`There was an error in the entityContainerConnector of ${entityId}!`, e, 'error');
  }
}

function mapDispatchToProps(dispatch: Dispatch, { entityId, parentContext, fieldName, index }: IEntityContainerProps) {
  return bindActionCreators(
    {
      // these actions are generic, meaning they are independent of the entity
      // in which they are called
      onReferenceTemporaryEntityAction: ControlActions.setTemporaryEntityReference,
      onMove: moveModule,
      onUpload: uploadFiles,

      // the following actions are specific to this entity
      onSaveChild: (payload: SaveChildPayload) =>
        saveChild({
          id: entityId,
          ...payload,
        }),
      onCancelChild: (payload: CancelChildPayload) =>
        cancelChild({
          id: entityId,
          ...payload,
        }),
      onDeleteChild: (payload: DeleteChildPayload) =>
        deleteChild({
          id: entityId,
          ...payload,
        }),
      onAddNewChild: (payload: AddChildPayload) =>
        addChild({
          id: entityId,
          ...payload,
        }),
      onChangeChild: (payload: ChangeChildPayload) =>
        changeChild({
          id: entityId,
          ...payload,
        }),
      onMoveUp: (_e?: SyntheticEvent<HTMLElement>) =>
        moveModule({
          parentContext,
          fieldName,
          id: entityId,
          targetIndex: index - 1,
        }),
      onMoveDown: (_e?: SyntheticEvent<HTMLElement>) =>
        moveModule({
          parentContext,
          fieldName,
          id: entityId,
          targetIndex: index + 1,
        }),

      onStartEdit: (e?: EditEntityPayload | SyntheticEvent<HTMLElement>) =>
        startEditingEntity({
          id: entityId,
          // if e has a property bubbles, assume it is an event and dont use it
          // to spread, otherwise use the argument as payload
          ...((e as SyntheticEvent<HTMLElement>)?.bubbles ? {} : (e as Partial<EditEntityPayload>)),
        }),

      onStopEdit: (id?: string | SyntheticEvent<HTMLElement>) =>
        stopEditingEntity(typeof id === 'string' ? id : entityId),
      onSave: () =>
        saveEntityWorker({
          entityId: entityId,
        }),
      onChange: (name: string, val: ChangesetValueType, isPush?: boolean, isSplice?: number, id?: string) =>
        changeWorkableEntity({
          id: id || entityId,
          changeSet: {
            push: isPush,
            splice: isSplice,
            fieldName: name,
            value: val,
          },
        }),
      onDelete: (_e?: SyntheticEvent<HTMLElement>) => {
        (dispatch as AppDispatch)(
          deleteChild({
            childId: entityId,
            id: parentContext,
            fieldName,
          }),
        );
      },
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps);
