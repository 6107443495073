import React from 'react';

import RightSidebarContentContainer from '~components/sidebar/content/RightSidebarContentContainer';

const RightSidebar = () => {
  return (
    <div className="c-right-sidebar">
      <RightSidebarContentContainer />
    </div>
  );
};

export default RightSidebar;
